
import { defineComponent, onMounted, ref, watch } from "vue";

const CFormInputNumber = defineComponent({
  name: "CFormInputNumber",
  emits: ["update:modelValue"],
  props: {
    elementID: { type: String, required: true },
    placeholder: { type: String, default: "" },
    width: { type: String, default: "24rem" },
    min: { default: "" },
    max: { default: "" },
    hasError: { type: Boolean, default: false },
    hasAutofocus: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    modelValue: { required: true },
  },
  setup(props, { emit }) {
    const inputNumber = ref<HTMLInputElement | null>(null);

    function handleEmit() {
      if (!inputNumber.value?.value || Number(props.min) > Number(inputNumber.value?.value)) {
        if (inputNumber.value) inputNumber.value.value = props.min;
        emit("update:modelValue", Number(props.min));
      } else emit("update:modelValue", Number(inputNumber.value?.value));
    }

    watch(
      () => props.modelValue,
      (value) => {
        if (!inputNumber.value) return;

        if (value) inputNumber.value.value = String(value);
        else inputNumber.value.value = "";
      }
    );

    onMounted(() => {
      if (inputNumber.value) inputNumber.value.value = props.modelValue === null ? "" : String(props.modelValue);
      if (props.hasAutofocus) inputNumber.value?.focus();
    });

    return { inputNumber, handleEmit };
  },
});

export type InputNumberEvent = {
  target: { value: number };
};

export default CFormInputNumber;
